
import Vue from "vue";
import dayjs from "dayjs";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import { onlineEx } from "@/common/utils/fileEx";
@Component({
  components: {},
  filters: {
    // 时间格式化
    timeFormat(time: string | number) {
      return dayjs(time).format("YYYY-MM-DD HH:mm");
    },
    unitTransform(size: number) {
      let val: string | number;
      if (size < 1024) {
        val = size + "B";
      } else if (size < 1024 * 1024) {
        val = size / 1024;
        val = val.toFixed(2) + "KB";
      } else if (size < 1024 * 1024 * 1024) {
        val = size / (1024 * 1024);
        val = val.toFixed(2) + "MB";
      } else {
        val = size / (1024 * 1024 * 1024);
        val = val.toFixed(2) + "GB";
      }
      return val;
    },
  },
})
export default class ClassComponent extends Vue {
  title: string = "";
  selItem: any = {};
  fileList: any[] = [];
  breadcrumbList: any[] = [{ userFileName: "个人文档", id: "" }];

  @Prop({
    type: String,
    default: "share",
  })
  type!: string;

  @Prop({
    type: String,
  })
  dialogClass!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  visible!: boolean;

  created() {
    // 暂时注释
    this.getFileList('', 'doc');
  }

  mounted() {
    console.log('this.type', this.type)
  }

  getFileList(id?: any, moduleType?: string, parentId?: any) {
    this.$apis.userFilePageList({ id: id ? id : '', moduleType: moduleType ? moduleType : "", parentId: parentId ? parentId : "" }).then((res: any) => {

      if (this.type === "share") {
        // alert('999')
        this.fileList = res.list;
      } else {
        this.fileList = res.list.filter((i: any) => {
          return onlineEx.includes(i.extensionName) || parseInt(i.isDir) === 1;
        });
      }
    });
  }

  onClickCancle() {
    this.$emit("close", false);
  }

  dbClickDir(item: any) {






    if (parseInt(item.isDir) !== 1) return;

    console.log('文件分享列表的item', item);

    if (item != this.breadcrumbList.slice(-1)[0]) {
      this.breadcrumbList.push(item);
    }
    this.getFileList(item.id, 'doc');
    this.selItem = item; 4
  }

  BackToPrevious() {
    console.log(
      "breadcrumbClick",
      this.breadcrumbList,
      this.breadcrumbList.slice(-2, -1)
    );
    let i = this.breadcrumbList.slice(-2, -1)[0];
    this.breadcrumbList.splice(-1, 1);
    this.getFileList(i.idm, 'doc');
    this.selItem = i;
  }

  breadcrumbClick(item: any, index: any) {
    console.log(item, index);
    if (item == this.breadcrumbList.slice(-1)[0]) {
      return;
    }
    this.breadcrumbList.splice(
      index + 1,
      this.breadcrumbList.length - index - 1
    );
    this.getFileList(item.id, 'doc');
    this.selItem = item;
  }

  // 分享
  handleShare(type: string, value: any) {
    this.$emit("share", type, value);
  }

  // 协作
  handleCooperation(type: string, value: any) {
    this.$emit("cooperation", type, value);
  }
}

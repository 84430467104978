
import Vue from "vue";
import dayjs from "dayjs";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import { Popconfirm } from "ant-design-vue";
import { namespace } from "vuex-class";
import FileModal from "@/components/Modal/FileModal.vue";
import ShareModal from "@/views/mydoc/ShareModal.vue";

const UserModule = namespace("user");

@Component({
  name: "Share",
  components: {
    [Popconfirm.name]: Popconfirm,
    FileModal,
    ShareModal,
  },
  filters: {
    //局部过滤器
  },
})
export default class Share extends Vue {
  @UserModule.State("companyId") companyId!: string;

  public listData: any[] = [];
  public selectedRowKeys: Array<string | number> = [];
  public checkAll: boolean = false;
  public fileShareVisible: boolean = false;
  public shareVisible: boolean = false;
  public shareLoading: boolean = false;
  public shareType: string = "public";
  public shareModalKey: {
    shareName: string;
    shareUrl: string;
    accessPwd?: string;
  } = { shareName: "", shareUrl: "" };
  public expireType: number = 0;
  public shareFile: any = {};
  public wrapLoading: boolean = false;

  public columns: any[] = [
    {
      title: "",
      dataIndex: "fileType",
      align: "left",
      scopedSlots: { customRender: "fileType" },
      width: 40,
    },
    {
      title: "名称",
      dataIndex: "shareName",
      align: "left",
      scopedSlots: { customRender: "shareName" },
    },
    {
      title: "分享范围",
      dataIndex: "scope",
      align: "center",
      customRender: (value: string | null) => {
          if(value==='REGISTERED') {
            return ('仅Dana AI智能体用户')
          } else {
            return ('所有人可见')
          }
      },
    },
    {
      title: "分享时间",
      dataIndex: "createTime",
      align: "center",
      customRender: (value: string | number) => {
        return dayjs(value).format("YYYY-MM-DD HH:mm");
      },
    },
    {
      title: "状态",
      dataIndex: "expireType",
      align: "center",
      customRender: (value: number) => {
        if (value === 1) {
          return "7天有效";
        } else if (value === 2) {
          return "1天有效";
        } else {
          return "永久有效";
        }
      },
    },
    {
      title: "下载次数",
      dataIndex: "downloadCount",
      align: "center",
      customRender: (value: string | number) => {
        return value + "次";
      },
    },
    {
      title: "保存次数",
      dataIndex: "forwardCount",
      align: "center",
      customRender: (value: string | number) => {
        return value + "次";
      },
    },
    {
      title: "操作",
      dataIndex: "",
      align: "center",
      scopedSlots: { customRender: "action" },
    },
  ];

  public visible: boolean = false;
  public shareKey: {
    shareName: string;
    shareUrl: string;
    accessPwd?: string;
  } = {
    shareName: "",
    shareUrl: "",
    accessPwd: "",
  };

  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  // @Prop()
  //计算属性
  // get computedMsg(): string {
  //   return ''
  // }
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  @Watch("selectedRowKeys", { deep: true })
  change(val: Array<number | string>) {
    // 维护全选状态标记
    this.checkAll = val.length === this.listData.length;
  }
  //ref 引用元素 Ref('ref')
  // @Ref()

  // 获取数据
  queryListData() {
    this.wrapLoading = true;
    this.$apis
      .sharePageShare({
        isPage: 0,
        companyId: 'person',
      })
      .then((res: any) => {
        if (res.code === "200") {
          this.listData = res.data.list;
        }
      })
      .finally(() => {
        this.wrapLoading = false;
      });
  }

  // 表格多选
  onSelectChange(selectedRowKeys: Array<number | string>) {
    this.selectedRowKeys = selectedRowKeys;
  }

  // 全选/清空
  onCheckAll() {
    if (this.checkAll) {
      this.selectedRowKeys = [];
    } else {
      this.selectedRowKeys = this.listData.map((el: any) => el.id);
    }
  }

  // 查看分享链接
    // 嵌入到主应用里去了，这里要加入微应用的路由
  handleViewShare(value: any) {
    this.shareKey = {
      ...value,
      shareUrl: `${process.env.VUE_APP_FILE_VIVW}/star-pan-web/fileShare?shareKey=${value.shareUrl}`,
    };
    this.visible = true;
  }

  // 复制 分享链接
  handleCopy() {
    var inp = document.createElement("input");
    if (this.shareKey.accessPwd) {
      inp.value = `链接:${this.shareKey.shareUrl} 密码:${this.shareKey.accessPwd}`;
    } else {
      inp.value = this.shareKey.shareUrl;
    }
    inp.style.opacity = "0";
    document.body.appendChild(inp);
    inp.select();
    document.execCommand("Copy");
    this.$message.success("复制成功");
    inp.remove();
  }



    // 复制(仅链接)
    handleCopyUrl(): void {
    var inp = document.createElement("input");
    // if (this.expireTypeShow) {
      inp.value = this.shareKey.shareUrl;
    // } else {
    //   inp.value = `链接:${this.shareUrl} 密码:${this.accessPwd}`;
    // }
    inp.style.opacity = "0";
    document.body.appendChild(inp);
    inp.select();
    document.execCommand("Copy");
    this.$message.success("复制成功");
    inp.remove();
  }


  // 取消分享
  cancelShare(value: any) {
    this.$apis
      .shareCancel({
        ids: value.id,
      })
      .then((res: any) => {
        if (res.code === "200") {
          this.$message.success("取消分享成功");
          this.queryListData();
        }
      });
  }

  cancelShareAll() {
    if (this.selectedRowKeys.length === 0) {
      this.$message.warning("请选择最少一条需要取消的分享");
      return;
    }
    let that = this;
    this.$confirm({
      title: `已选择${that.selectedRowKeys.length}个分享文件,确认要取消吗?`,
      content: "取消分享后,分享的链接将不可用，请知悉！",
      okText: "是",
      okType: "danger",
      cancelText: "否",
      onOk() {
        that.$apis
          .shareCancel({
            ids: that.selectedRowKeys.join(","),
          })
          .then((res: any) => {
            if (res.code === "200") {
              that.$message.success("取消分享成功");
              that.queryListData();
            }
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  // 点击分享
  handleShare(type: string, value: any) {
    this.shareType = type;
    this.shareFile = value;
    if (type === "public") {
      this.shareFileOk();
    } else {
      this.expireType = 0;
      this.shareVisible = true;
    }
  }

  // 分享过期时间
  handleExpire(value: number) {
    this.expireType = value;
    this.shareFileOk();
  }

  // 分享文件确认
  shareFileOk() {
    this.fileShareVisible = false;
    let name: string =
      this.shareFile.isDir === 0
        ? `${this.shareFile.userFileName}.${this.shareFile.extensionName}`
        : `${this.shareFile.userFileName}`;
    let params = {
      shareType: this.shareType,
      userFileIds: [this.shareFile.id],
      expireType: this.shareType === "public" ? undefined : this.expireType,
      shareName: name,
      companyId: 'person',
    };
    this.shareVisible = true;
    this.shareLoading = true;
      // 嵌入到主应用里去了，这里要加入微应用的路由


 

    this.$apis.shareCreateShare(params).then((res: any) => {
      this.shareModalKey = {
        ...res.data,
        shareUrl: `${process.env.VUE_APP_FILE_VIVW}/star-pan-web/fileShare?shareKey=${res.data.shareUrl}`,
        shareName: name,
      };
      this.shareLoading = false;
      this.queryListData();
    });
  }

  //创建前钩子函数
  created(): void {
    this.queryListData();
  }
}
